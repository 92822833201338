.modal.closed {
  display:none
}

.modal.open {

}

.modal__bg {
  display: block;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 11000;
  background: rgba(0,0,0,.2);
}
.modal__content {
  z-index: 11001;
  background: #fff;
  padding: 10px;
  position: fixed;
  top: 40px;
  left: 20px;
  right: 20px;
  box-shadow: 5px 5px 15px rgba(0,0,0,.5);
}
