.messageBar {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-size: 13px;
}


.messageBar_row {
  flex-direction: row;
  display: flex;
  padding: 5px 10px 5px 50px;
  background: rgba(0,0,0,.7);
}
.messageBar_row2 {
  background: rgba(0,0,0,.9);
}

.messageBar_message {
  flex: auto;
  overflow: auto;
  text-align: center;
}
