.menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10000;
  display: flex;
}

.menu_left {
  width: 0px;
  background: white;
  height: 100%;
  transition: all 0.4s;
  overflow: auto;
}

.menu.open {
  width: 100%;
}

.menu.open .menu_left {
  width: 260px;
}

.menu_left_inner {
  width: 240px;
}

.menu_left_inner h2 {
  padding-left: 20px;
  margin: 0;
}
.menu_left_inner ul {
  list-style: none;
  padding: 0;
}
.menu_left_inner ul li {
  padding: 10px 15px 3px 20px;
}
.menu_left_inner .mui-checkbox {
  margin: 0;
}
.menu_left_inner .fa {
  width: 1.3em;
}

.menu_right {
  flex: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.menu_open-button {
  position: absolute;
  bottom: 5px;
  left: 10px;
  transition: all 0.4s;
}

.menu.open .menu_open-button {
  left: -28px;
}

.donate {
  background: #307d7d;
  padding: 10px;
  color: white;
  line-height: 1.2em;
  border-radius: 4px;
  display: block;
  text-align: center;
  font-size: 1.1rem;
}
.donate:hover {
  text-decoration: none;
  background: #439494;
}
