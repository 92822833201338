.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main_content {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.main .tabs {
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
}

.main .tabs .tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: #666;
  border-top: 1px solid #666;
}

.main .tabs .tab.selected {
  background-color: #eeeeee;
}

.main .tabs .tab:hover {
  cursor: pointer;
  color: #000;
}
