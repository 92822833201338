.mapContainer {
  flex: 1;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.mapContainer .map {
  height: 100%;
}

.mapContainer .button {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.ol-overlaycontainer-stopevent {
  position: absolute;
  top: 10px;
  left: 10px;
}

.ol-rotate-reset {
  display: none;
}

.ol-zoom-out,
.ol-zoom-in {
  background: #fff;
  color: #666;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0 10px 10px 0;
  font-size: 2rem;
}

.ol-scale-line-inner {
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 2px black solid;
  border-left: 1px black solid;
  border-right: 1px black solid;
  padding: 2px 0;
}
