.target {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  pointer-events: none;
  margin-top: -50px;
  margin-left: -50px;
}

.target::before {
  width: 140px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  height: 0px;
  content: "";
  display: block;
  margin-top: 50px;
  margin-left: -20px;
}

.target::after {
  height: 140px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  width: 0px;
  content: "";
  display: block;
  margin-top: -70px;
  margin-left: 50px;
}
